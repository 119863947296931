//Backgrounds
import background_frontpage_webp from "./images/backgrounds/frontpage_background.webp";
import background_blue from "./images/backgrounds/Background_Hand_Tileable_Blue.png";
import background_red from "./images/backgrounds/Background_Hand_Tileable_Red.png";

import red_hand from "./images/T_Keyart_Hand_Red_Crop.png";
import red_hand_webp from "./images/T_Keyart_Hand_Red_Crop.webp";
import blue_hand from "./images/T_Keyart_Hand_Blue_Crop.png";
import blue_hand_webp from "./images/T_Keyart_Hand_Blue_Crop.webp";

import ahod_logo from "./images/AHOD_Banner.png";
import ahod_logo_webp from "./images/AHOD_Banner.webp";

import accolade_dga from "./images/accolades/accolade_dga_white.png";
import accolade_dga_webp from "./images/accolades/accolade_dga_white.webp";
import accolade_indigo from "./images/accolades/accolade_indigo_white.png";
import accolade_indigo_webp from "./images/accolades/accolade_indigo_white.webp";
import accolade_gdwc from "./images/accolades/accolade_gdwc_white.png";
import accolade_gdwc_webp from "./images/accolades/accolade_gdwc_white.webp";

import platform_steam_nologo from "./images/platforms/Platform_Steam_NoLogo.png";
import platform_steam_nologo_webp from "./images/platforms/Platform_Steam_NoLogo.webp";

import screenshot_mole from "./images/screenshots/screenshot_mole.png";
import screenshot_mole_webp from "./images/screenshots/screenshot_mole.webp";
import screenshot_mole2 from "./images/screenshots/screenshot_mole2.png";
import screenshot_mole2_webp from "./images/screenshots/screenshot_mole2.webp";
import screenshot_clouds from "./images/screenshots/screenshot_clouds.png";
import screenshot_clouds_webp from "./images/screenshots/screenshot_clouds.webp";
import screenshot_pushpull from "./images/screenshots/screenshot_pushpull.png";
import screenshot_pushpull_webp from "./images/screenshots/screenshot_pushpull.webp";
import screenshot_extarm from "./images/screenshots/screenshot_extarm.png";
import screenshot_extarm_webp from "./images/screenshots/screenshot_extarm.webp";

import platform_steam from "./images/platforms/Platform_Steam.png";
import platform_steam_webp from "./images/platforms/Platform_Steam.webp";
import platform_nintendo_switch from "./images/platforms/Platform_NintendoSwitch.png";
import platform_nintendo_switch_webp from "./images/platforms/Platform_NintendoSwitch.webp";
import platform_unity from "./images/platforms/Platform_Unity.png";
import platform_unity_webp from "./images/platforms/Platform_Unity.webp";
import platform_sci from "./images/platforms/Platform_SCI.png";
import platform_sci_webp from "./images/platforms/Platform_SCI.webp";

import logo_mantasaur_white from "./images/logos/mantasaur/logo_mantasaur_white.png";
import logo_mantasaur_white_webp from "./images/logos/mantasaur/logo_mantasaur_white.webp";
import logo_mantasaur_horizontal_white from "./images/logos/mantasaur/logo_mantasaur_horizontal_white.png";
import logo_mantasaur_horizontal_white_webp from "./images/logos/mantasaur/logo_mantasaur_horizontal_white.webp";

import social_steam from "./images/logos/socials/regular/Socials_Steam.png";

const url_steamstore =
  "https://store.steampowered.com/app/2122880/All_Hands_on_Deck/";

import ruben_logo from "./images/team/Ruben_Profile.png";
import ruben_logo_webp from "./images/team/Ruben_Profile.webp";
import wouter_logo from "./images/team/Wouter_Profile.png";
import wouter_logo_webp from "./images/team/Wouter_Profile.webp";
import douwe_logo from "./images/team/Douwe_Profile.png";
import douwe_logo_webp from "./images/team/Douwe_Profile.webp";
import manou_logo from "./images/team/Manou_Profile.png";
import manou_logo_webp from "./images/team/Manou_Profile.webp";

import "./App.css";
import HeaderMenu from "./HeaderMenu";
import SocialsRow from "./SocialsRow";
import ProfilePicture from "./ProfilePicture";
import ImgWithFallback from "./ImgWithFallback";
import "tw-elements";
import LazyLoad from "react-lazyload";

function App() {
  return (
    <main id="top" className="w-full h-full bg-white scroll-smooth">
      <div>
        <HeaderMenu />
        <div
          style={{
            backgroundImage: `url(${background_frontpage_webp})`,
          }}
          className="bg-no-repeat bg-bottom-center bg-cover pt-24 mb-16 md:mb-[10%]"
        >
          {/* AHOD Responsive Banner */}
          <div className="flex flex-col">
            <ImgWithFallback
              src={ahod_logo_webp}
              fallback={ahod_logo}
              alt="AHOD logo"
              className="mx-auto w-[60%] object-contain mb-[15vh] 
                  md:mb-0 md:w-[33%]"
            />

            <div className="flex flex-row flex-auto justify-between -mb-[10%]">
              <ImgWithFallback
                src={red_hand_webp}
                fallback={red_hand}
                alt="Red hand character"
                pictureClass="w-[34%] object-contain
                md:w-[20%]"
              />

              <ImgWithFallback
                src={blue_hand_webp}
                fallback={blue_hand}
                alt="Blue hand character"
                pictureClass="w-[34%] object-contain
                md:w-[20%] ml-full"
              />
            </div>
          </div>
        </div>

        <div className="bg-white mb-10">
          <div className="flex flex-col items-center md:px-[5%]">
            {/* Buttons */}
            <div
              className="flex flex-col items-center space-y-4 px-[2%]
            md:space-y-0 md:space-x-4 md:flex-row md:mb-0 md:px-[20%]"
            >
              <div className="flex flex-row w-full">
                <p
                  className="my-auto text-right w-1/3 font-spork uppercase text-[6vw] text-[#fa913c] tracking-tight
                md:text-[3.5vw]"
                >
                  Wishlist
                  <br /> now on
                </p>

                <div className="w-1/3 p-[2%]">
                  <div className="m-auto">
                    <a
                      href={url_steamstore}
                      className="bg-button active:bg-button-pressed select-none flex justify-center bg-contain bg-no-repeat bg-center w-full h-full drop-shadow-[-2px_12px_10px_rgba(0,0,0,0.3)]"
                    >
                      <img
                        src={social_steam}
                        alt="Steam logo"
                        className="buttonlogo object-contain select-none p-[23%] active:invert-[75%]"
                      />
                    </a>
                  </div>
                </div>
                <ImgWithFallback
                  src={platform_steam_nologo_webp}
                  fallback={platform_steam_nologo}
                  alt="Steam"
                  pictureClass="w-1/3"
                  className="invert-[85%] object-contain h-full"
                />
              </div>

              {/* <div className="flex flex-row w-full">
                <p
                className="my-auto text-right w-1/3 font-spork uppercase text-[6vw] text-[#2eaca9] tracking-tight
                md:text-[2.8vw]"
                >
                Free demo
                <br /> available
                </p>
                <div className="w-1/3 p-[2%]">
                <div className="m-auto">
                <a
                href={url_itchstore}
                className="bg-button active:bg-button-pressed select-none flex justify-center bg-contain bg-no-repeat bg-center w-full h-full drop-shadow-[-2px_12px_10px_rgba(0,0,0,0.3)]"
                >
                <img
                src={social_itch_circular}
                alt="Itch.io logo"
                className="buttonlogo object-contain select-none p-[23%] active:invert-[75%]"
                />
                </a>
                </div>
                </div>
                <ImgWithFallback
                src={platform_itch_nologo_webp}
                fallback={platform_itch_nologo}
                alt="Itch.io"
                pictureClass="w-1/3"
                className="invert-[85%] object-contain h-full"
                />
              </div> */}
            </div>

            <p className=" italic mb-4 md:text-[1.5vw] text-[4vw]">
              Free demo available
            </p>

            {/* Accolades */}
            <div
              className="flex flex-col items-center mb-10 px-[10%] w-full space-y-6 
            sm:space-y-10 md:space-y-0 md:space-x-4 md:flex-row md:mb-20 md:px-0
            md:h-[14vmax]"
            >
              <div className="w-full h-full">
                <ImgWithFallback
                  src={accolade_dga_webp}
                  fallback={accolade_dga}
                  alt="Accolade Dutch Game Awards best student game"
                  className="invert-[85%] w-full object-contain 
                  md:h-full"
                />
              </div>
              <div className="w-full h-full">
                <ImgWithFallback
                  src={accolade_gdwc_webp}
                  fallback={accolade_gdwc}
                  alt="GDWC finalist"
                  className="invert-[85%] w-full object-contain h-[40vw]
                  md:h-full md:pt-6"
                />
              </div>
              <div className="w-full h-full">
                <ImgWithFallback
                  src={accolade_indigo_webp}
                  fallback={accolade_indigo}
                  alt="Accolade Indigo nominee"
                  className="invert-[85%] w-full object-contain 
                  md:h-full"
                />
              </div>
            </div>
          </div>
        </div>

        {/* style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"  */}

        <div
          style={{ backgroundImage: `url(${background_blue})` }}
          className="text-white bg-fixed pt-10 pb-20 px-2 shadow-inner-tb bg-[length:128px_128px] md:bg-[length:256px_256px]"
        >
          <h1 className="mb-7 text-center uppercase text-[6vw] leading-[6vw] md:mb-14">
            Play as a pair, act like one!
          </h1>

          <div className="flex flex-col items-center md:flex-row md:space-x-10 md:px-[4vw] lg:px-[8vw]">
            <div className="mb-10 md:mb-0 md:w-1/2">
              <ImgWithFallback
                src={screenshot_mole_webp}
                fallback={screenshot_mole}
                alt="Screenshot mole"
                className="rounded-3xl border-[5px] drop-shadow-[5px_5px_10px_rgba(0,0,0,0.5)]"
              />
            </div>
            <div className="flex flex-col md:justify-center md:w-1/2">
              <h2 className="uppercase mb-5 text-[6vw] leading-[6vw] tracking-tighter md:text-[2.5vw] md:leading-[2.5vw]">
                Find all the missing plushies
              </h2>

              <p className="backdrop-blur-[5px] md:text-lg">
                All the plushies in the daycare have gone missing!
                <br />
                You get to play as the hands of each kid searching for their
                dear friend in their own fantasy world. <br />
                Can you find out who or what caused this strange event?
              </p>
            </div>
          </div>
        </div>

        <div className="text-gray-800 bg-white px-2 pt-10 pb-20 md:py-20">
          <div className="flex flex-col-reverse items-center md:flex-row md:space-x-10 md:px-[4vw] lg:px-[8vw]">
            <div className="flex flex-col md:justify-center md:w-1/2">
              <h2 className="uppercase mb-5 text-[6vw] leading-[6vw] tracking-tighter md:text-[2.5vw] md:leading-[2.5vw]">
                Dive into a playful puzzle adventure
              </h2>

              <p className="md:text-lg">
                All Hands on Deck is a local co-op puzzle platformer. Combine
                and use various items with each other in this playful puzzle
                platformer to reach higher places and create something entirely
                new! In this game, all can experience the same! Just swap items
                and you're good to go. How handy is that!
              </p>
            </div>
            <div className="mb-10 md:mb-0 md:w-1/2">
              <LazyLoad offset={300}>
                <ImgWithFallback
                  src={screenshot_extarm_webp}
                  fallback={screenshot_extarm}
                  alt="Screenshot extendable arm"
                  className="rounded-3xl border-[5px] drop-shadow-[5px_5px_10px_rgba(0,0,0,0.5)]"
                />
              </LazyLoad>
            </div>
          </div>
        </div>

        <div
          style={{ backgroundImage: `url(${background_red})` }}
          className="pt-10 pb-20 px-2 bg-fixed text-white shadow-inner-tb bg-[length:128px_128px] md:bg-[length:256px_256px] md:py-20"
        >
          <div className="flex flex-col items-center md:flex-row md:space-x-10 md:px-[4vw] lg:px-[8vw]">
            <div className="mb-10 md:mb-0 md:w-1/2">
              <LazyLoad offset={300}>
                <ImgWithFallback
                  src={screenshot_clouds_webp}
                  fallback={screenshot_clouds}
                  alt="Screenshot clouds"
                  className="rounded-3xl border-[5px] drop-shadow-[5px_5px_10px_rgba(0,0,0,0.5)]"
                />
              </LazyLoad>
            </div>
            <div className="flex flex-col md:justify-center md:w-1/2">
              <h2 className="uppercase mb-5 text-[6vw] leading-[6vw] tracking-tighter md:text-[2.5vw] md:leading-[2.5vw]">
                Venture into multiple fantasy worlds
              </h2>

              <p className="backdrop-blur-[5px] md:text-lg">
                Go on an adventure into multiple mysterious and colourful
                worlds. Each world has a different theme, multiple levels and of
                course a fluffy friend waiting for you to rescue them. Spread
                throughout each world are many secrets, cosmetics and additional
                challenges. Can you find them all?
              </p>
            </div>
          </div>
        </div>

        <div
          id="trailer"
          className="text-gray-800 bg-white pt-10 px-2 md:px-[10vw] md:pt-4"
        >
          {/* Trailer*/}
          <LazyLoad offset={300}>
            <iframe
              src="https://www.youtube-nocookie.com/embed/ay9ion6X08k"
              title="All Hands on Deck Trailer"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full aspect-video m-auto mb-10"
            ></iframe>
          </LazyLoad>

          <iframe
            width="885"
            height="500"
            src="https://45a5b7c1.sibforms.com/serve/MUIFAJ_xuk5P3CIzuEsrVT_AEYHEbRJCEdnbQhLMpVQwQX9n3k2nskG536jskeDWNyoVOFjaV-oZgmOar6WbDqJ5Eb7MeDOIaV_Ep9DcUiiXJmjC-NpZTwBN3y5K9fE8uyKeWQZMaChGAvOl5jEI5cPwXsI0PYFOirBFhLKP1KF5cvKCBYWcXjTNzGO6EwCMV2gFXTNmF0Npom_o"
            frameborder="0"
            className="display:block ml-auto mr-auto max-w-full max-h-full my-[-50px]"
          ></iframe>

          <div className="mb-7 flex flex-row px-6 md:space-x-12 md:my-20">
            <div className="flex flex-col w-full h-full">
              <LazyLoad offset={300}>
                <ImgWithFallback
                  src={platform_nintendo_switch_webp}
                  fallback={platform_nintendo_switch}
                  alt="Platform Nintendo Switch"
                  className="invert-[50%] object-contain mx-auto h-14 py-1 md:h-32"
                />
              </LazyLoad>
              <p
                className="uppercase text-center italic text-gray-500 text-xs font-bold -translate-y-1 
              md:text-lg"
              >
                TBD
              </p>
            </div>
            <div className="flex flex-col w-full h-full">
              <LazyLoad offset={300}>
                <ImgWithFallback
                  src={platform_steam_webp}
                  fallback={platform_steam}
                  alt="Platform Steam"
                  className="invert-[50%] object-contain mx-auto h-14 md:h-32"
                />
              </LazyLoad>
              <p
                className="uppercase text-center italic text-gray-500 text-xs font-bold -translate-y-1 
              md:text-lg"
              >
                Demo available
              </p>
            </div>
          </div>

          <div
            id="carouselScreenshots"
            className="carousel slide relative mx-auto md:w-[80%]"
            data-bs-ride="carousel"
          >
            <div className="hidden carousel-indicators absolute right-0 bottom-0 left-0 sm:flex justify-center p-0 mb-4">
              <button
                data-bs-target="#carouselScreenshots"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselScreenshots"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselScreenshots"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselScreenshots"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
            </div>
            <div className="carousel-inner relative w-full overflow-hidden rounded-md md:rounded-3xl">
              <div className="carousel-item active float-left w-full">
                <ImgWithFallback
                  src={screenshot_pushpull_webp}
                  fallback={screenshot_pushpull}
                  className="block w-full"
                  alt="Push pull"
                />
              </div>
              <div className="carousel-item float-left w-full">
                <ImgWithFallback
                  src={screenshot_clouds_webp}
                  fallback={screenshot_clouds}
                  className="block w-full"
                  alt="Clouds"
                />
              </div>
              <div className="carousel-item float-left w-full">
                <ImgWithFallback
                  src={screenshot_mole2_webp}
                  fallback={screenshot_mole2}
                  className="block w-full"
                  alt="Mole victory"
                />
              </div>
              <div className="carousel-item float-left w-full">
                <ImgWithFallback
                  src={screenshot_extarm_webp}
                  fallback={screenshot_extarm}
                  className="block w-full"
                  alt="Extendable arm"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
              type="button"
              data-bs-target="#carouselScreenshots"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon inline-block bg-no-repeat"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
              type="button"
              data-bs-target="#carouselScreenshots"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon inline-block bg-no-repeat"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

          <LazyLoad offset={300}>
            <ImgWithFallback
              src={platform_unity_webp}
              fallback={platform_unity}
              alt="Made with Unity"
              className="my-12 mx-auto object-contain px-[10%] md:my-20"
            />
          </LazyLoad>
        </div>

        <h1 className="uppercase mx-auto mb-5 text-center font-bold text-[6vw] leading-[6vw] md:text-[2.5vw] md:leading-[2.5vw]">
          Funded by
        </h1>
        <LazyLoad offset={300}>
          <ImgWithFallback
            src={platform_sci_webp}
            fallback={platform_sci}
            alt="creative industries fund NL"
            className="my-12 mx-auto w-48 object-contain md:my-20"
          />
        </LazyLoad>

        <div className="text-white pt-12 pb-20 shadow-inner-t bg-[#2eaca9] px-2">
          <LazyLoad offset={300}>
            <ImgWithFallback
              src={logo_mantasaur_white_webp}
              fallback={logo_mantasaur_white}
              alt="Mantasaur logo"
              className="mb-10 mx-auto w-[60vw] md:w-[18vw] md:object-contain"
            />
          </LazyLoad>
          <p id="theteam" className="mb-10 mx-auto text-center md:w-[54vw]">
            Studio Mantasaur is a Dutch startup game studio based in The
            Netherlands, Utrecht. We are a small team of 4 and like to make
            light-hearted and colourful experiences.
          </p>
          <div className="flex flex-col mx-auto items-center mb-20 space-y-16 md:space-y-6 lg:flex-row lg:space-y-0 lg:space-x-10 lg:w-[90%] lg:items-start">
            <ProfilePicture
              className="md:w-3/4 lg:w-1/4"
              img={ruben_logo_webp}
              fallback={ruben_logo}
              isleft={true}
              name="Ruben Hooijer"
              text="Without Ruben, there wouldn't be a game at all. 
              He is responsible for each system, mechanic, and itty bit of code. 
              There is no problem Ruben can't solve yet. 
              He also loves table tennis."
            />
            <ProfilePicture
              className="md:w-3/4 lg:w-1/4"
              img={manou_logo_webp}
              fallback={manou_logo}
              isleft={false}
              name="Manou IJpelaar"
              text="Lead Designer, if you have difficulty with the amount of puzzles, she is to blame. 
              Manou designs levels that are challenging yet intuitive. 
              And the number of hand puns she makes drives us all a little insane..."
            />
            <ProfilePicture
              className="md:w-3/4 lg:w-1/4"
              img={wouter_logo_webp}
              fallback={wouter_logo}
              isleft={true}
              name="Wouter Kamies"
              text="3D Artist with a passion for bringing assets to life. 
              proudly responsible for the idea of hands with little feet. 
              Wouter is also in charge of the visual effects, social media, and design of this website! 
              A hand of all trades!"
            />
            <ProfilePicture
              className="md:w-3/4 lg:w-1/4"
              img={douwe_logo_webp}
              fallback={douwe_logo}
              isleft={false}
              name="Douwe Heijmerink"
              text="The creator of all the environments you see! 
              Douwe is responsible for creating playful and colourful environments that we hope you will remember!"
            />
          </div>
          <h2 className="text-center uppercase mb-3">Contact</h2>
          <p className="text-center mb-3">
            Feel free to send us a message at{" "}
            <span>
              <a
                href="mailto:contact@studiomantasaur.nl"
                className="font-semibold"
              >
                contact@studiomantasaur.nl
              </a>
            </span>
            .
            <br />
            Studio Mantasaur is not looking for any interns.
          </p>
        </div>

        <footer className="text-white bg-[#2eaca9] bottom-0 left-0 h-auto w-full py-2">
          <SocialsRow className="mb-10 w-[60vw] sm:w-[45vw] md:w-[30vw] lg:w-[20vw]" />

          <a href="#" className="flex justify-center px-2 h-full">
            <span className="text-lg">Copyright © 2022 - 2024</span>
            <ImgWithFallback
              src={logo_mantasaur_horizontal_white_webp}
              fallback={logo_mantasaur_horizontal_white}
              alt="Logo Studio Mantasaur"
              className="h-10 object-contain ml-2 mr-2"
            />
          </a>
        </footer>
      </div>
    </main>
  );
}

export default App;
